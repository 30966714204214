import React from "react"
import { graphql } from "gatsby"

import SingleProject from "../components/poster/singleProject"

import Layout from "../components/layout"
import SEO from "../components/seo"

const Projekt = ({ data }) => {
  const project = data.projektyYaml
  const ProjektImg = project.pictures[1].childImageSharp.original
  function posterClass() {
    if (ProjektImg.height > ProjektImg.width) {
      return "pionowy"
    } else if (ProjektImg.height === ProjektImg.width) {
      return "full"
    } else {
      return "poziomy"
    }
  }
  return (
    <Layout>
      <SEO
        title={project.name}
        description={project.desc[0]}
        image={project.pictures[1].childImageSharp.original.src}
      />

      <div>
        <SingleProject
          name={project.name}
          year={project.year}
          desc={project.desc}
          plakatDrukInfo={project.drukInfo}
          plakatFormat={project.format}
          imgSrc={project.pictures}
          posterClass={posterClass}
        />
      </div>
    </Layout>
  )
}
export const query = graphql`
  query($slug: String!) {
    projektyYaml(fields: { slug: { eq: $slug } }) {
      name
      category
      year
      desc
      drukInfo
      format
      pictures {
        childImageSharp {
          original {
            src
            width
            height
          }
        }
      }
    }
  }
`

export default Projekt
