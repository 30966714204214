import React from "react"
import CartContext from "../../context/cart-context"
let paragrafNum = 0

class SingleProject extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      currentImg: this.props.imgSrc[1].childImageSharp.original.src,
    }
  }
  handleImgChange(newSrc) {
    this.setState({
      currentImg: newSrc,
    })
  }
  render() {
    return (
      <CartContext.Consumer>
        {cart => (
          <div className="plakatContent">
            <div className="plakatImg800">
              <img
                className={this.props.posterClass()}
                alt={this.props.name}
                src={this.state.currentImg}
              />
            </div>
            <div className="posterOtherMockups mobileOnly">
              {this.props.imgSrc.slice(2).map(item => {
                return (
                  <img
                    onClick={this.handleImgChange.bind(
                      this,
                      item.childImageSharp.original.src
                    )}
                    alt={this.props.name}
                    src={item.childImageSharp.original.src}
                  />
                )
              })}
            </div>
            <div className="plakatDesc">
              <h1>
                {this.props.name}, {this.props.year}
              </h1>
              {this.props.desc.map(paragraf => {
                let paragrafKey = "par" + paragrafNum
                paragrafNum++
                return <p key={paragrafKey}>{paragraf}</p>
              })}

              <div className="posterOtherMockups desktopOnly">
                {this.props.imgSrc.slice(1).map(item => {
                  return (
                    <img
                      onClick={this.handleImgChange.bind(
                        this,
                        item.childImageSharp.original.src
                      )}
                      alt={this.props.name}
                      src={item.childImageSharp.original.src}
                    />
                  )
                })}
              </div>
            </div>
          </div>
        )}
      </CartContext.Consumer>
    )
  }
}
export default SingleProject
